import React, { Component } from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectListItemPlaceholder from '../../components/UI/Placeholder/ProjectListItemPlaceholder/ProjectListItemPlacholder';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import ProjectListItem from './ProjectListItem/ProjectListItem';
import ProjectListCounter from './ProjectListCounter/ProjectListCounter';
import CallToAction from '../../components/CallToAction/CallToAction';
import styles from './ProjectList.module.scss';
import axios from '../../axios-base';
import { Link } from 'react-router-dom';
import {Row, Col, Input, Label} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {default as CustomInput}  from '../../components/UI/Input/Input';
import {cleanLocationProp, sendVirtualPageView} from '../../shared/utility';
import qs from 'qs';
import ReactJoyride, {STATUS} from 'react-joyride';
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";

class ProjectList extends Component {

    constructor(props){
        super(props);
        this.state = {
            projectsCount: 0,
            error: false,
            projects: null,
            selectedProjectId: null,
            phrase: '',
            categoryOptions: null,
            categorySelected: '',
            cta: null
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.categoryHandler = this.categoryHandler.bind(this);
    }

    componentDidMount () {
        const query = qs.parse(this.props.location.search.slice(1))
        axios.get('/cta',{
            params: {
                page: cleanLocationProp(this.props.location.pathname)
            }})
            .then(response => {
                    this.setState({cta: response.data[0]});
            })
            .catch(error => {
                this.setState({error: true});
                console.log(error);
            });

        axios.get(`/projects.json?category=${query.category}`)
            .then(response => {
                this.setState({
                    projects: response.data,
                    projectsCount: response.data.length,
                    categorySelected: query.category !== undefined ?  query.category : ""
                });
            })
            .catch(error => {
                this.setState({error: true});
                console.log(error);
            });

        axios.get('/options')
            .then(response => {
                    let availableCategories = response.data[0].projectForm.projectCategory;
                    if(availableCategories && availableCategories.elementConfig.options.length > 1) {
                        let firstOption = () => {
                            if(availableCategories.dependent){
                                return {value: "", displayValue: "Alle"}
                            } else {
                                return {value: "", displayValue: "Wählen Sie eine " + availableCategories.elementConfig.label, disabled: true}
                            }
                        }

                        availableCategories.elementConfig.options.unshift(firstOption());
                        availableCategories.elementConfig.label = "Wählen Sie eine " + availableCategories.elementConfig.label;
                        this.setState({
                            categoryOptions: availableCategories,
                            steps: [{
                                target: '.category-select',
                                content: `Über das Dropdown-Menü können Sie Ihre Auswahl jederzeit anpassen. ${availableCategories.elementConfig.label } wie z.B. ${availableCategories.elementConfig.options[1].displayValue} oder ${availableCategories.elementConfig.options[2].displayValue}`,
                                disableBeacon: true,
                                placement: 'top'
                            }],
                        });
                    } else {
                        this.setState({
                            steps: [{
                                target: '.project-search',
                                content: 'Suchen Sie nach Ihren gewünschten Projekten',
                                disableBeacon: true,
                                placement: 'top'
                            }]
                        });
                    }
            })
            .catch(error => {
                this.setState({error: true});
                console.log(error);
            });
        sendVirtualPageView("Teilnehmer")
    }

    onChangeHandler = (e) => {
        let searchString = e.target.value;
        if (this.state.categorySelected !== '' && this.state.categoryOptions.dependent) {
            axios.get("/projects.json?category=")
                .then(response => {
                    this.setState({
                        projects: response.data,
                        projectsCount: response.data.length
                    });
                })
                .catch(error => {
                    this.setState({error: true});
                    console.log(error);
                });
            this.setState({
                phrase: searchString,
                categorySelected: ''
            }, () => {
                this.props.history.push({
                    search: `?category=`
                })
            });
        } else {
            this.setState({ phrase: searchString });
        }
    };

    categoryHandler = (e) => {
        if(e.target.value !== this.state.categorySelected) {
            this.setState({
                categorySelected: e.target.value,
                phrase: ''
            }, () => {
                axios.get(`/projects.json?category=${this.state.categorySelected}`)
                    .then(response => {
                        this.setState({projects: response.data});
                        this.setState({projectsCount: response.data.length});
                    })
                    .then(() => {
                        this.props.history.push({
                            search: `?category=${this.state.categorySelected}`
                        })
                    })
                    .catch(error => {
                        this.setState({error: true});
                        console.log(error);
                    });
            });
        }
    };

    render() {
        let categoryForm;
        if (this.state.categoryOptions){
            categoryForm = (
                <CustomInput
                    wrapperCSSClass='category-select mb-3'
                    elementType={this.state.categoryOptions.elementType}
                    elementConfig={this.state.categoryOptions.elementConfig}
                    value={this.state.categorySelected}
                    changed={this.categoryHandler}
                    shouldValidate={this.state.categoryOptions.valid} />
            );
        }

        let projectsRender = (
            <Aux>
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
            </Aux>
        );

        if (this.state.votingPhases.type && this.state.projects) {
            projectsRender = this.state.projects
                .filter((project) => {
                    // @todo: Filter is associated with phases, therefore it can move up component hierarchy to app.js or be encompassed in the following reduce method
                    if(this.props.votingPhases.type.length > 1){
                        //we have a 2 part campaign
                        if (this.props.votingPhases.voteStartedFinale) {
                            return project.isFinalist;
                        } else {
                            return project;
                        }
                    } else {
                        //global
                        return project;
                    }

                })
                .reduce((displayedProjects, project) => {
                    let projectVoteCount = '-';
                    let projectRank = '-';
                    if(this.props.votingPhases.type.length > 1){
                        // @todo: We can move the project rank check out of the phase check as it is repeated for both phases
                        if (this.props.votingPhases.voteStartedFinale) {
                            if(project.voteCountFinale) { projectVoteCount = project.voteCountFinale; }
                            if(this.props.votingPhases.voteStarted && project.rank) { projectRank = project.rank; }
                        } else {
                            if(this.props.votingPhases.voteStarted && project.voteCount) { projectVoteCount = project.voteCount; }
                            if(this.props.votingPhases.voteStarted && project.rank) { projectRank = project.rank; }
                        }
                    } else {
                        // @Todo: We can remove voteStartedFinale since this is now redundant? Potential refactor needed
                        if(this.props.votingPhases.voteStartedFinale && project.voteCountFinale) {
                            projectRank = project.rank;
                            projectVoteCount = project.voteCountFinale;
                        }
                    }

                    let projectLink = (
                        // Todo: Deep-Link zur Ressource definieren und Pretty-Link erstellen
                        <Link className={styles.ProjectListLink} to={'./' + project._id} key={project._id}>
                            <ProjectListItem
                                display={project.contestantName.toLowerCase().includes(this.state.phrase.toLowerCase()) ? "" : "d-none"}
                                rank={projectRank}
                                title={project.contestantName}
                                subtitle={project.projectTitle}
                                voteCount={projectVoteCount}
                            />
                        </Link>
                    );
                    displayedProjects.push(projectLink);
                    return displayedProjects;
                }, []);
        }

        let cta = <CallToActionPlaceholder/>;

        if (this.state.cta){
            cta = <CallToAction
                headline={this.state.cta.headline}
                copy={this.state.cta.copy}
                buttonText={this.state.cta.buttonText}
                buttonColor={this.state.cta.buttonColor}
                linkUrl={this.state.cta.linkUrl}
                outline={this.state.cta.outline}
                buttonVisibility={this.state.cta.buttonVisibility}
            />
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Teilnehmer`}</title>
                </Helmet>
                {Cookies.get('bigfoot_cookie-consent') && !Cookies.get('bigfoot_tour-projects') && <ReactJoyride
                    steps={this.state.steps}
                    disabledScrolling
                    hideCloseButton
                    scrollDuration={800}
                    styles={{
                        options: {
                            zIndex: 1021,
                            primaryColor: styles.primaryColor,
                        },
                        tooltipContent: {
                            padding: '0px'
                        }
                    }}
                    locale={{back: 'Zurück', close: 'Schließen', last: 'Letzte', next: 'Nächste', open: 'Öffnen', skip: 'Überspringen'}}
                    callback={({ status }) => {
                        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
                            Cookies.set('bigfoot_tour-projects', true, {expires: 180});
                        }
                    }}
                    floaterProps = {{
                        disableAnimation: true,
                        disableFlip: true}} /> }

                {/* CallToAction */}
                <Row className="my-3">
                    <Col>
                        {cta}
                    </Col>
                </Row>
                <Row className="my-2">
                    <ProjectListCounter
                        projectsCount={this.state.projectsCount}
                        categories={this.state.categoryOptions}/>
                </Row>
                <Row >
                    <Col className="mb-3 project-search">
                        <Label for="Teilnehmer suchen">Suchen Sie nach einem Teilnehmernamen</Label>
                        <div className={styles.SearchWrapper + " position-relative"}>
                            <FontAwesomeIcon className={styles.SearchIcon} icon={faSearch}/>
                            <Input onChange={this.onChangeHandler} className={styles.SearchField + " me-sm-2"} placeholder="Teilnehmer suchen" type="search" aria-label="Teilnehmer suchen" value={this.state.phrase}/>
                        </div>
                    </Col>
                        {categoryForm}
                </Row>
                <Row>
                    <Col className="px-0 p-lg-3 min-vh-100">
                        {projectsRender}
                    </Col>
                </Row>
            </Aux>
        );
    }
}

export default ProjectList;
