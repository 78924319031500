import React, {Component} from 'react';
import Aux from '../../hoc/Aux/Aux';
import {checkValidity} from '../../shared/utility';
import Input from '../../components/UI/Input/Input';
import ReCaptcha from 'reaptcha';
import {Button, Col, FormText} from 'reactstrap';
import axios from '../../axios-base';
import Cookies from "js-cookie";

class SMSVote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseToken: '',
            votingButton: 'Initialisiere...',
            votingReady: false,
            SMSCodeConfig: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    inputMode: "text",
                    placeholder: 'Beispiel: PKX8QKZ8',
                    label: 'Ihr SMS-Code zur Stimmabgabe',
                    columns: 12,
                    disabled: false
                },
                value: '',
                validation: {
                    required: true,
                    isSMSCode: true,
                    maxLength: 8,
                    minLength: 8
                },
                valid: false,
                errorMessage: 'Bitte geben Sie eine gültige SMS Code an',
                touched: false,
                ifAuth: false
            }
        };
        this.captcha = null;
        this.navigateToPage = this.navigateToPage.bind(this);
    }

    componentDidMount() {
        if(this.props.projectCategory){
            Cookies.set('bigfoot_redirect-category', this.props.projectCategory , {expires: 180})
        }
    }

    navigateToPage = () => {
        this.props.history.push('/code-anfordern/');
    };

    onChangeHandler = event => {
        const userInput = event.target.value.toUpperCase();

        const updatedSMSCode = {
            ...this.state.SMSCodeConfig
        };

        updatedSMSCode.value = userInput;
        updatedSMSCode.valid = checkValidity(updatedSMSCode.value, updatedSMSCode.validation)
        updatedSMSCode.touched = true;

        let votingReady = true;
        votingReady = updatedSMSCode.valid && votingReady;

        this.setState({
            SMSCodeConfig: updatedSMSCode,
            votingReady: votingReady
        });
    };

    onVoteHandler = () => {
        this.captcha.execute();
    };

    onVerify = (responseToken) => {

        axios.patch('/sms', {
            // 'fingerprint': this.state.fingerprint,
            'responseToken': responseToken,
            'code': this.state.SMSCodeConfig.value,
            'projectId': this.props.match.params.id
        })
            .then((response) => {
                if (response !== undefined) {

                    const updatedSMSCode = {...this.state.SMSCodeConfig};
                    updatedSMSCode.elementConfig.disabled = true;

                    this.setState({
                        votingReady: false,
                        votingButton: 'Sie haben bereits abgestimmt',
                        SMSCodeConfig: updatedSMSCode
                    });
                    Cookies.remove('bigfoot_code-requested');
                    this.props.history.push('/stimme-bestaetigt');
                } else {
                    this.setState({
                        votingReady: false,
                        votingButton: 'Sie haben bereits abgestimmt!'
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
        this.captcha.reset();
    };

    onLoad = () => {
        this.setState({
            votingButton: 'Jetzt per SMS abstimmen!', // Vote by SMS
            votingReady: false
        });
    };

    onError = () => {
        console.log('Es ist ein Fehler beim Verifizieren des reCAPTCHAS aufgetreten!');
        this.setState({votingButton: 'Verifiziere reCAPTCHA...'});
    };


    render() {
        let form = (
            <Aux>
                <Input
                    wrapperCSSClass="px-0 mb-0"
                    type={this.state.SMSCodeConfig.elementType}
                    name="SMSCode"
                    id="SMSCode"
                    placeholder={this.state.SMSCodeConfig.elementConfig.placeholder}
                    elementType={this.state.SMSCodeConfig.elementType}
                    elementConfig={this.state.SMSCodeConfig.elementConfig}
                    value={this.state.SMSCodeConfig.value}
                    changed={(event) => this.onChangeHandler(event)}
                    shouldValidate={this.state.SMSCodeConfig.validation}
                    touched={this.state.SMSCodeConfig.touched}
                    errorMessage={this.state.SMSCodeConfig.errorMessage}
                    invalid={!this.state.SMSCodeConfig.valid}
                    formText={this.state.SMSCodeConfig.formText} />
                <FormText className="mb-3">Bitte Ihren 8-stelligen SMS-Code eingeben. <span style={{cursor: 'pointer'}} className="text-primary" role="button" onClick={this.navigateToPage}>Noch keinen Code?</span>
                </FormText>
            </Aux>
        );

        return(
            <Col xs="12" sm="6" lg="12">
                <ReCaptcha
                    ref={e => (this.captcha = e)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    onVerify={this.onVerify}
                    onError={this.onError}
                    onLoad={this.onLoad}
                    size="invisible"
                />
                {form}
                <Button
                    block
                    disabled={!this.state.votingReady}
                    color="primary"
                    onClick={this.onVoteHandler}
                >
                    {this.state.votingButton}
                </Button>
                <p className="text-black-50 small my-2">Diese Seite wird durch reCAPTCHA geschützt.
                    Es gelten die <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und die <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von Google.</p>
            </Col>
        );
    }

}

export default SMSVote;