// Polyfills for older browsers as per React docs (https://reactjs.org/docs/javascript-environment-requirements.html)
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es7/object';
import 'raf/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import WebFont from 'webfontloader';
import thunk from 'redux-thunk';

import App from './App';
import './assets/scss/main.scss?raw';
//import registerServiceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';

// Redux browser extension setup for development env
const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const rootReducer = combineReducers({
    auth: authReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

// Todo: Better place for WebFont Loader?
if (process.env.REACT_APP_FONT_URL) {
    WebFont.load({ custom: { families: [process.env.REACT_APP_FONT_FAMILIES], urls: [process.env.REACT_APP_FONT_URL] } });
}

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

createRoot(document.getElementById('root')).render(app);
//registerServiceWorker();
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map(name => caches.delete(name)));
            });
        }
    });
}
