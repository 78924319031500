import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'

const shareButton = (props) => {
    let button;
    const shareTitle = props.shareTitle;
    const shareText = encodeURIComponent(`Hallo! Unterstütze uns indem du hier für ${shareTitle} abstimmst`);
    const shareUrl = window.location.href;

    switch (props.shareType) {
        case 'email':
            button = (
                <a
                    rel="noreferrer"
                    href={`mailto:?subject=Jetzt für uns abstimmen!&body=${shareText}: ${shareUrl}`}
                    target="_blank"
                    className="btn-outline-email btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" className="pe-1"/>Mailen
                </a>
            );
            break;
        case 'facebook':
            button = (
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    rel="external noopener noreferrer"
                    className="btn-outline-facebook btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faFacebook} size="lg" className="pe-1"/>Teilen
                </a>
            );
            break;
        case 'twitter':
            button = (
                <a
                    href={`https://twitter.com/share?url=${shareUrl}&text=${shareText}`}
                    target="_blank"
                    rel="external noopener noreferrer"
                    className="btn-outline-twitter btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faTwitter} size="lg" className="pe-1"/>Twittern
                </a>
            );
            break;
        case 'whatsApp':
            button = (
                <a
                    rel="noreferrer"
                    href={`whatsapp://send?text=${shareText}%20${shareUrl}`}
                    target="_blank"
                    className="btn-outline-whatsapp btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faWhatsapp} size="lg" className="pe-1"/>Teilen
                </a>
            );
            break;
        default:
            button = (
                <span>Kein Dienst definiert</span>
            );
            break;
    }

    return button;
};

export default shareButton;
